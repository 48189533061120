<template>
  <div>
    <div style="margin-bottom: 15px">
      <table>
        <tr v-if="chosenSpeciality">
          <td style="padding-right: 10px; text-align: right"><b>Специальность:</b></td>
          <td>{{ chosenSpeciality.name }} ({{ chosenSpeciality.code }})</td>
          <td style="padding-left: 10px"><a class="a-no-href" @click="$store.commit( 'clear' )">&times;</a></td>
        </tr>
        <tr v-if="chosenSemester">
          <td style="padding-right: 10px; text-align: right"><b>Семестр:</b></td>
          <td>{{ chosenSemester.semester }} семестр ({{ chosenSemester.year }} курс)</td>
          <td style="padding-left: 10px"><a class="a-no-href" @click="$store.commit( 'setChosenSemester', null )">&times;</a></td>
        </tr>
      </table>
    </div>
    <div v-if="!chosenSpeciality">
      <b>Специальность:</b>
      <div v-for="speciality in data.specialities" v-bind:key="speciality.code">
        <b-button-group style="margin-bottom: 5px; width: 100%">
          <b-button variant="outline-secondary" style="width: 105px!important;" v-if="speciality.code && speciality.code !== ''" disabled><b>{{ speciality.code }}</b></b-button>
          <b-button variant="outline-success" class="btn-outline-rnimu" style="text-align: initial !important; width: 100%" @click="$store.commit( 'setChosenSpeciality', speciality )">{{ speciality.name }}</b-button>
        </b-button-group>
      </div>
    </div>
    <div v-if="chosenSpeciality && !chosenSemester">
      <b>Семестр:</b>
      <div v-for="semester in chosenSpeciality.semesters" v-bind:key="semester.semester">
        <b-button-group style="margin-bottom: 5px; width: 100%">
          <b-button variant="outline-secondary" style="width: 105px!important;" v-if="semester.year" disabled><b>{{ semester.year }} курс</b></b-button>
          <b-button variant="outline-success" class="btn-outline-rnimu" style="text-align: initial !important; width: 100%" @click="$store.commit( 'setChosenSemester', semester )">{{ semester.semester }} семестр</b-button>
        </b-button-group>
      </div>
    </div>
    <div v-if="chosenSemester">
      <b>Версия:</b>
      <div v-for="version in chosenSemester.versions" v-bind:key="version.version">
        <b-button-group style="margin-bottom: 5px; width: 100%">
          <b-button variant="outline-success" class="btn-outline-rnimu" style="text-align: initial !important; width: 100%" @click="chooseVersion( version.id )">{{ version.version }}</b-button>
        </b-button-group>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import data from '@/data/data.json';

export default {
  name: 'main-page',
  data: () => ({
    data: data
  }),
  computed: {
    ...mapState({
      chosenSpeciality: state => state.timetable.chosenSpeciality,
      chosenSemester: state => state.timetable.chosenSemester,
    })
  },
  methods: {
    chooseVersion( version ) {
      this.$router.push( { path: '/View', query: { id: version } } )
    }
  }
}
</script>

<style scoped>

</style>
