<template>
  <div>
    <div class="row" style="margin-bottom: 15px">
      <div class="col-auto d-flex flex-column">
        <b-button class="btn-outline-rnimu mt-auto" variant="outline-success" style="margin-top: auto" @click="$router.push( '/' )">
          &larr; Назад
        </b-button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-auto col-sm-12">
        <div class="card" style="margin-bottom: 10px">
          <div class="card-body">
            <table>
              <tr>
                <td style="padding-right: 10px; text-align: right"><b>Специальность:</b></td>
                <td>{{ chosenSpeciality.name }}</td>
              </tr>
              <tr>
                <td style="padding-right: 10px; text-align: right"><b>Семестр:</b></td>
                <td>{{ chosenSemester.semester }} ({{ chosenSemester.year }} курс)</td>
              </tr>
              <tr>
                <td style="padding-right: 10px; text-align: right"><b>Начало семестра:</b></td>
                <td>{{ chosenSemester.beginDate }}</td>
              </tr>
              <tr>
                <td style="padding-right: 10px; text-align: right"><b>Конец семестра:</b></td>
                <td>{{ chosenSemester.endDate }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-auto col-sm-12">
        <div class="card" style="margin-bottom: 10px">
          <div class="card-body d-flex flex-row">
            <h5 class="card-title ml-auto mr-auto" style="margin-bottom: 0 !important;">Сейчас <span class="badge badge-success default-cursor-on-hover">{{ getCurrentWeek() }}</span> неделя</h5>
          </div>
        </div>
      </div>
      <div class="col-md-auto col-sm-12">
        <div class="card">
          <div class="card-body">
            <div class="col-md-auto col-sm-12" style="margin-top: 10px">
              <b>Тип</b>
              <b-form-select size="sm" v-model="type" :options="data.types"></b-form-select>
            </div>
            <div class="col-md-auto col-sm-12" style="margin-top: 10px">
              <b>Группа</b>
              <b-form-select size="sm" v-model="group" :options="chosenSemester.groups"></b-form-select>
            </div>
            <div class="col-md-auto col-sm-12" style="margin-top: 10px">
              <b-form-checkbox id="checkbox-1" v-model="show" name="checkbox-1" value="actual" unchecked-value="any" :disabled="getCurrentWeek() === 'ещё не учебная'">
                Только для текущей недели
              </b-form-checkbox>
            </div>
            <div class="col-auto d-flex flex-column" style="margin-top: 15px">
              <b-button size="sm" class="btn-rnimu mt-auto" style="margin-top: auto" @click="applyFilter">
                Применить
              </b-button>
            </div>
            <div class="col-auto d-flex flex-column" style="margin-top: 15px">
              <b-button size="sm" class="btn-outline-rnimu mt-auto" variant="outline-success" style="margin-top: auto" @click="clear">
                Очистить
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-tabs content-class="mt-3" style="margin-bottom: 55px; margin-top: 25px" fill>
      <b-tab :title="day.name" v-for="day in display" v-bind:key="day.name" :active="chosenTab === day.name" @click="chosenTab = day.name">
        <div style="margin-top: 15px" v-if="day.subjects.length">
          <div class="card shadow" v-for="subject in day.subjects" v-bind:key="subject.time + subject.name" style="margin-bottom: 10px">
            <div class="card-body">
              <h5 class="card-title"><span class="badge badge-info default-cursor-on-hover" v-b-tooltip.hover.top="'Время'">{{ subject.time }}</span> <span :class="findType( subject.type ).css" v-b-tooltip.hover.top="'Тип занятия'">{{ findType( subject.type ).text }}</span> {{ subject.name }}</h5>
              <div style="margin-top: 15px">
                <span class="badge badge-light default-cursor-on-hover" v-b-tooltip.hover.top="'Группа'" v-for="group in subject.groups" v-bind:key="group" style="margin-right: 5px">{{ findGroup( group ) }}</span>
              </div>
              <div style="margin-top: 15px" v-if="subject.weeks.length">
                <span class="badge badge-secondary default-cursor-on-hover" v-b-tooltip.hover.top="'Неделя'" v-for="week in subject.weeks" v-bind:key="week" style="margin-right: 5px">{{ week }}</span>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 15px" v-if="!day.subjects.length">
          <i>В выбранный день нет занятий.</i>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import data from '@/data/data.json';
import moment from 'moment';

export default {
  name: "View-Version",
  computed: {
    ...mapState({
      chosenSpeciality: state => state.timetable.chosenSpeciality,
      chosenSemester: state => state.timetable.chosenSemester,
    })
  },
  data:() => ({
    version: null,
    display: [],
    data: data,
    type: null,
    group: null,
    show: 'any',
    chosenTab: "Понедельник"
  }),
  methods: {
    clear() {
      this.type = null;
      this.group = null;
      this.show = "any";

      this.display = [];

      this.display.push({
        name: "Понедельник",
        subjects: this.version.mon
      });

      this.display.push({
        name: "Вторник",
        subjects: this.version.tue
      });

      this.display.push({
        name: "Среда",
        subjects: this.version.wed
      });

      this.display.push({
        name: "Четверг",
        subjects: this.version.thu
      });

      this.display.push({
        name: "Пятница",
        subjects: this.version.fri
      });

      this.display.push({
        name: "Суббота",
        subjects: this.version.sat
      });

      this.display.push({
        name: "Воскресенье",
        subjects: this.version.sun
      });
    },
    findType( id ) {
      let ret = null;
      this.data.types.map( type => {
        if ( type.value == id ) ret = type;
      } )
      return ret;
    },
    findGroup( id ) {
      let ret = '';
      this.$store.state.timetable.chosenSemester.groups.map( group => {
        if ( group.value == id ) ret = group.text;
      } )
      return ret;
    },
    checkFilter( subject ) {
      let include = true;
      if ( this.type != null ) {
        include = subject.type == this.type;
      }
      if ( this.group != null && include ) {
        include = subject.groups.includes( this.group );
      }
      if ( this.show === 'actual' && include ) {
        if ( subject.weeks.length && !subject.weeks.includes( this.getCurrentWeek() ) ) {
          include = false;
        }
      }
      return include;
    },
    applyFilter() {
      this.display = [];
      let monSubjects = [];
      this.version.mon.map( subject => {
        if ( this.checkFilter( subject ) == true ) monSubjects.push( subject );
      } )
      this.display.push({
        name: "Понедельник",
        subjects: monSubjects
      });

      let tueSubjects = [];
      this.version.tue.map( subject => {
        if ( this.checkFilter( subject ) == true ) tueSubjects.push( subject );
      } )
      this.display.push({
        name: "Вторник",
        subjects: tueSubjects
      });

      let wedSubjects = [];
      this.version.wed.map( subject => {
        if ( this.checkFilter( subject ) == true ) wedSubjects.push( subject );
      } )
      this.display.push({
        name: "Среда",
        subjects: wedSubjects
      });

      let thuSubjects = [];
      this.version.thu.map( subject => {
        if ( this.checkFilter( subject ) == true ) thuSubjects.push( subject );
      } )
      this.display.push({
        name: "Четверг",
        subjects: thuSubjects
      });

      let friSubjects = [];
      this.version.fri.map( subject => {
        if ( this.checkFilter( subject ) == true ) friSubjects.push( subject );
      } )
      this.display.push({
        name: "Пятница",
        subjects: friSubjects
      });

      let satSubjects = [];
      this.version.sat.map( subject => {
        if ( this.checkFilter( subject ) == true ) satSubjects.push( subject );
      } )
      this.display.push({
        name: "Суббота",
        subjects: satSubjects
      });

      let sunSubjects = [];
      this.version.sun.map( subject => {
        if ( this.checkFilter( subject ) == true ) sunSubjects.push( subject );
      } )
      this.display.push({
        name: "Воскресенье",
        subjects: sunSubjects
      });
    },
    getCurrentWeek() {
      let beginDate = moment( this.chosenSemester.beginDate, 'DD.MM.yyyy' );
      let now = moment();
      //let now = moment( "02.02.2021", 'DD.MM.yyyy' );
      if ( now.isBefore( beginDate ) ) return 'ещё не учебная';
      if ( now.diff( beginDate, 'days' ) === 0 ) return '1';
      return Math.ceil( ( now.diff( beginDate, 'days' ) + 1 ) / 7 );
    }
  },
  mounted() {
    let id = this.$route.query.id;
    this.$store.state.timetable.chosenSemester.versions.map( version => {
      if ( id == version.id ) this.version = version;
    } )

    this.display.push({
      name: "Понедельник",
      subjects: this.version.mon
    });

    this.display.push({
      name: "Вторник",
      subjects: this.version.tue
    });

    this.display.push({
      name: "Среда",
      subjects: this.version.wed
    });

    this.display.push({
      name: "Четверг",
      subjects: this.version.thu
    });

    this.display.push({
      name: "Пятница",
      subjects: this.version.fri
    });

    this.display.push({
      name: "Суббота",
      subjects: this.version.sat
    });

    this.display.push({
      name: "Воскресенье",
      subjects: this.version.sun
    });
  }
}
</script>

<style scoped>

</style>
