const state = {
    chosenSpeciality: null,
    chosenSemester: null,
}

const mutations = {
    setChosenSpeciality( state, data ) {
        state.chosenSpeciality = data;
    },
    setChosenSemester( state, data ) {
        state.chosenSemester = data;
    },
    clear( state ) {
        state.chosenSpeciality = null;
        state.chosenSemester = null;
    }
}

export default {
    state,
    mutations
}
