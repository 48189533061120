import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
import timetable from "./modules/timetable";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        timetable,
    },
    plugins: [createPersistedState()]
});

export default store
