<template>
  <div id="app">
    <b-navbar toggleable="lg" type="dark" variant="info" class="bg-rnimu">
      <b-navbar-brand href="#" @click="goHome">Расписание</b-navbar-brand>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item-dropdown class="pc">
            <template #button-content>
              {{ chosenSpeciality ? chosenSpeciality.name : 'Специальность' }}
            </template>
            <b-dropdown-item v-for="speciality in data.specialities" v-bind:key="speciality.code" @click="setSpeciality( speciality )">{{ speciality.name }} ({{ speciality.code }})</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown class="pc" v-if="chosenSpeciality">
            <template #button-content>
              {{ chosenSemester ? chosenSemester.semester + ' семестр' : 'Семестр' }}
            </template>
            <b-dropdown-item v-for="semester in chosenSpeciality.semesters" v-bind:key="semester.semester" @click="setSemester( semester )">{{ semester.semester }} ({{ semester.semester }} курс)</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div class="container" style="margin-top: 15px">
      <router-view/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import data from '@/data/data.json';

export default {
  name: 'App',
  data: () => ({
    data: data
  }),
  computed: {
    ...mapState({
      chosenSpeciality: state => state.timetable.chosenSpeciality,
      chosenSemester: state => state.timetable.chosenSemester,
    })
  },
  methods: {
    goHome() {
      if ( this.$route.path !== '/' ) this.$router.push( '/' );
      this.$store.commit( 'clear' );
    },
    setSpeciality( speciality ) {
      if ( this.$route.path !== '/' ) this.$router.push( '/' );
      this.$store.commit( 'clear' );
      this.$store.commit( 'setChosenSpeciality', speciality );
    },
    setSemester( semester ) {
      if ( this.$route.path !== '/' ) this.$router.push( '/' );
      this.$store.commit( 'setChosenSemester', semester );
    }
  }
}
</script>

<style>

</style>
